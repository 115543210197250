#page-oportunitati-exchanges {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#page-oportunitati-exchanges header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--gap-medium) 0;
}

#page-oportunitati-exchanges .airplane-swiper {
  margin-bottom: var(--gap-xl);
  width: 100%;
}

#page-oportunitati-exchanges .airplane-swiper .airplane-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#page-oportunitati-exchanges .account {
  padding: var(--gap-xl) var(--gap-small);
  margin: var(--gap-xl) 0;
  background-color: var(--color-gray);

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: var(--border-radius-medium);
}

#page-oportunitati-exchanges section.reviews {
  border-top: var(--gap-medium) solid var(--color-fasmr-blue);
  align-self: stretch;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: var(--gap-big) var(--gap-small);
}

#page-oportunitati-exchanges section.reviews .map {
  position: relative;
  overflow: hidden;

  display: flex;
}

#page-oportunitati-exchanges section.reviews .map img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

#page-oportunitati-exchanges section.reviews .buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-medium);
  flex-wrap: wrap;

  margin: var(--gap-medium);
  z-index: 3;
}

#page-oportunitati-exchanges .no-reviews {
  display: flex;
  flex-direction: row;
  margin-bottom: calc(-1 * var(--gap-big));
  justify-content: stretch;
  color: var(--color-dark);
  background-color: var(--color-light);
  padding: var(--gap-big);
  border-radius: var(--border-radius-medium) var(--border-radius-medium) 0 0;
  box-shadow: var(--box-shadow-xs);
  gap: var(--gap-small);
}

#page-oportunitati-exchanges .no-reviews .big-container {
  display: flex;
  flex-direction: column;
  gap: var(--gap-medium);
  align-items: center;
  justify-content: center;
}
#page-oportunitati-exchanges .no-reviews .big-container .text {
  display: flex;
  align-items: stretch;
}
#page-oportunitati-exchanges .no-reviews .big-container .line-container {
  display: flex;
}
#page-oportunitati-exchanges .no-reviews .image-container {
  display: flex;
}

#page-oportunitati-exchanges .no-reviews .image-container img {
  object-fit: contain;
}

@media (max-width: 500px) {
  #page-oportunitati-exchanges .no-reviews {
    flex-direction: column;
  }
}

#page-oportunitati-exchanges .list {
  padding-top: var(--gap-big);
  gap: var(--gap-medium);
}

#page-oportunitati-exchanges .mid-container {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

#page-oportunitati-exchanges .mid-container .upper-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

#page-oportunitati-exchanges .mid-container .upper-container .air {
  display: flex;
  flex-direction: row;
  position: relative;
  /* right: 380px; */
  width: 15dvw;
  flex-shrink: 0;
  align-self: stretch;
  justify-self: stretch;
}

#page-oportunitati-exchanges .mid-container .upper-container .lane {
  display: flex;
  align-items: center;
  align-self: center;
  position: relative;
  /* top: 38%; */
}

#page-oportunitati-exchanges .mid-container .upper-container .air.left .lane {
  top: 0;
  opacity: 0;
  align-self: center;
}

#page-oportunitati-exchanges .mid-container .upper-container .airplane {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  background-color: transparent;
  overflow: hidden;

  max-height: 90px;
  align-self: center;

  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

#page-oportunitati-exchanges .mid-container .upper-container .airplane img {
  object-fit: contain;
  object-position: right;
}

#page-oportunitati-exchanges .mid-container .upper-container .title-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: #575757;
  position: relative;
  /* right: 300px; */
  padding: var(--gap-medium) var(--gap-small);
}

#page-oportunitati-exchanges .mid-container .upper-container .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  flex: 1;
  width: 100%;
}

#page-oportunitati-exchanges
  .mid-container
  .upper-container
  .title-container
  .button {
  display: flex;
  color: white;
  font-weight: bold;
  margin-right: 30px;
  align-items: center;
}

#page-oportunitati-exchanges .mid-container .lower-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  gap: var(--gap-medium);
}

#page-oportunitati-exchanges .mid-container .lower-container .subtitle {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  position: relative;
  /* top: -50px; */
  margin-top: 20px;
}

#page-oportunitati-exchanges .mid-container .lower-container img {
  height: 200px;
  object-fit: contain;
  align-self: stretch;
}

#page-oportunitati-exchanges .mid-container .lower-container .line {
  display: flex;
}
@media (max-width: 500px) {
  #page-oportunitati-exchanges .mid-container .upper-container .airplane,
  #page-oportunitati-exchanges .mid-container .upper-container .lane {
    display: none;
  }

  #page-oportunitati-exchanges .mid-container .upper-container .air {
    width: var(--gap-small);
  }
}

#page-oportunitati-exchanges section.reviews .country-title {
  color: var(--color-dark);
  text-align: center;
}
