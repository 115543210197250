.navigation-bar .menu-button:hover {
  cursor: pointer;
}

.navigation-bar .menu-button .fill-button {
  border: 2px solid var(--color-fasmr-blue);

  background: linear-gradient(
    to left,
    transparent 50%,
    var(--color-fasmr-blue) 50%
  );

  background-position: left;
  background-size: 200%;
}

.navigation-bar .menu-button:hover .fill-button {
  color: var(--color-fasmr-blue);
  cursor: pointer;
  background-position: right;
}

.navigation-bar .right-button {
  border: 2px solid var(--color-fasmr-blue);

  background: linear-gradient(
    to right,
    transparent 50%,
    var(--color-fasmr-blue) 50%
  );

  background-position: right;
  background-size: 200%;
}

.navigation-bar .right-button:hover {
  color: var(--color-fasmr-blue);
  cursor: pointer;
  background-position: left;
}

.navigation-bar .inflate {
  height: 100%;
}

.navigation-bar .inflate:hover {
  height: 105%;
}

.navigation-bar .navigation-button {
  color: var(--color-dark);

  background: linear-gradient(
    to top,
    transparent 50%,
    var(--color-fasmr-blue) 50%
  );

  background-position: bottom;
  background-size: 100% 200%;
}

.navigation-bar .navigation-button:hover,
.navigation-bar .navigation-button.active {
  background-position: top;
  color: white;
}

.navigation-bar .min-1150px {
  display: flex;
}
.navigation-bar .logo {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 1150px) {
  .navigation-bar .min-1150px {
    display: none;
  }
  .navigation-bar .logo {
    justify-content: center;
  }
}

@media screen and (max-width: 600px) {
  .navigation-bar .logo {
    display: none;
  }
}

.navigation-bar .side-right {
  display: flex;
}

@media screen and (max-width: 350px) {
  .navigation-bar .side-right {
    display: none;
  }
}

@media screen and (max-width: 180px) {
  .navigation-bar .neuron {
    display: none;
  }
}
