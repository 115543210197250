.page-doneaza {
  display: flex;
  flex-direction: column;
}

.page-doneaza .header {
  display: flex;
  flex-direction: row-reverse;
  align-items: stretch;
  position: relative;
}

.page-doneaza .header .header-image {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  object-fit: cover;
  position: relative;
  z-index: -1;
  width: 100%;
}

.page-doneaza .header .header-text {
  display: flex;
  color: red;
  margin-top: var(--gap-medium);
  margin-right: var(--gap-medium);
  position: absolute;

  max-width: 53dvw;
  text-align: right;
}

.page-doneaza .header .header-subtitle {
  display: flex;
  color: red;
  position: absolute;

  bottom: -10px;
  left: 32dvw;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
}

@media (max-width: 600px) {
  .page-doneaza .header .header-subtitle {
    bottom: 0;
    left: 0;
    align-items: center;
    transform: translateY(calc(100% + var(--gap-small)));
  }

  .page-doneaza .body {
    margin-top: var(--gap-big);
  }
}

.page-doneaza .body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-doneaza .body .body-paragh {
  margin-top: 35px;
  text-align: justify;
}

.page-doneaza .body .perfuzii {
  position: relative;
  margin: 0 var(--gap-small);
  margin-top: var(--gap-xl);
  gap: var(--gap-xl);

  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.page-doneaza .body .container-fasmr {
  display: flex;
  flex-direction: column;
  align-self: center;
  z-index: 0;
  margin-top: 60px;
  margin-bottom: 30px;
}

.page-doneaza .body .container-fasmr .logo {
  display: flex;
}

.page-doneaza .body .container-fasmr .icons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 30px;
  color: red;
  font-size: 3em;
}

.page-doneaza .body .container-fasmr .icons * {
  color: inherit;
  text-decoration: none;
  font-size: 1em;
}

.page-doneaza .body .container-fasmr .icons .label {
  font-size: 0.3em;
}

.page-doneaza .images {
  margin: var(--gap-small);
  align-self: stretch;
}

.page-doneaza .images img {
  object-fit: contain;
  object-position: top;
  overflow: hidden;
  border-radius: var(--border-radius-small);
}
