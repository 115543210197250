#redirect-page {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

#redirect-page .container {
  margin: var(--gap-xl) 0;
  gap: var(--gap-medium);
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
}

#redirect-page .text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 7dvh;
  margin-right: -30dvh;
}

#redirect-page .image {
  max-height: 60dvh;
  flex-grow: 1;
}

#redirect-page .image img {
  object-fit: contain;
}

@media (max-width: 650px) {
  #redirect-page .container {
    flex-direction: column-reverse;
    align-items: center;
  }
  #redirect-page .text-container {
    margin: 0;
  }
}
