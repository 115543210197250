.page-oportunitati-transmed {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: var(--color-light);
}
.page-oportunitati-transmed .header {
  display: flex;
  align-items: stretch;
  object-fit: contain;
  margin-bottom: 25px;
}

.page-oportunitati-transmed .header .img {
  object-fit: contain;
}
.page-oportunitati-transmed .mid-page {
  display: flex;
  flex-direction: column;
}

.page-oportunitati-transmed .mid-page .train {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.page-oportunitati-transmed .mid-page .train .vagon-swiper .swiper-slide {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.page-oportunitati-transmed .mid-page .train .vagon-swiper .connection-line {
  align-self: flex-end;
  border-top: 5px solid var(--color-dark);
  flex: 1;
  min-width: var(--gap-big);

  position: relative;
  top: calc(calc(100% - 8dvh) * -0.33);
}

.page-oportunitati-transmed
  .mid-page
  .train
  .vagon-swiper
  .swiper-slide
  .engine {
  margin-bottom: calc(8dvw / 2);
}

.page-oportunitati-transmed
  .mid-page
  .train
  .vagon-swiper
  .swiper-slide
  .engine
  img {
  object-fit: contain;
  max-height: 500px;
}

.page-oportunitati-transmed
  .mid-page
  .train
  .vagon-swiper
  .swiper-slide
  .engine-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--color-light);
  font-size: 3em;
  text-align: center;
}

.page-oportunitati-transmed .mid-page .train .left-side {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}
.page-oportunitati-transmed .mid-page .train .left-side .leftLine {
  display: flex;
  position: relative;
  top: 8%;
}

.page-oportunitati-transmed .mid-page .train .left-side .locomotiva {
  display: flex;
  align-items: center;
}

.page-oportunitati-transmed .mid-page .train .vagon-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
  position: relative;
  /* top: 10%; */
  /* padding: var(--gap-xl); */
  /* flex: 2; */
  width: min(calc(100dvw - var(--gap-xl)), 1000px);
}

.page-oportunitati-transmed .mid-page .train .vagon-container .vagon {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #474747;
  border-top-left-radius: var(--gap-medium);
  border-top-right-radius: var(--gap-medium);
  box-sizing: border-box;
  position: relative;
}
.page-oportunitati-transmed .mid-page .train .vagon-container .vagon .card {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--gap-medium);

  box-sizing: border-box;
}

.page-oportunitati-transmed
  .mid-page
  .train
  .vagon-container
  .vagon
  .information-container {
  position: absolute;
  top: calc(1.7% + var(--gap-medium));
  bottom: calc(2.4% + var(--gap-medium));
  left: calc(27% + var(--gap-medium));
  right: calc(3.6% + var(--gap-medium) + var(--gap-small));
  border-top-right-radius: calc(var(--gap-medium) + 15px);
  border-bottom-right-radius: calc(var(--gap-medium) + 15px);

  display: flex;
  align-items: stretch;
  gap: var(--gap-small);

  overflow: hidden;
}

.page-oportunitati-transmed
  .mid-page
  .train
  .vagon-container
  .vagon
  .information-container
  .background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.page-oportunitati-transmed
  .mid-page
  .train
  .vagon-container
  .vagon
  .information-container
  .background
  img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  opacity: 20%;
}

.page-oportunitati-transmed
  .mid-page
  .train
  .vagon-container
  .vagon
  .information-container
  .img-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0 1;
  align-self: center;
  aspect-ratio: 1 / 1;
}

@media (max-width: 850px) {
  .page-oportunitati-transmed
    .mid-page
    .train
    .vagon-container
    .vagon
    .information-container
    .img-container {
    display: none;
  }
}

.page-oportunitati-transmed
  .mid-page
  .train
  .vagon-container
  .vagon
  .information-container
  .img-container
  img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.page-oportunitati-transmed
  .mid-page
  .train
  .vagon-container
  .vagon
  .information-container
  .text-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: var(--gap-small);
}

@media (max-width: 650px) {
  .page-oportunitati-transmed
    .mid-page
    .train
    .vagon-container
    .vagon
    .information-container {
    top: calc(14% + var(--gap-medium));
    bottom: calc(0.8% + var(--gap-medium));
    left: calc(1% + var(--gap-medium));
    right: calc(1.3% + var(--gap-medium));
    border-top-right-radius: 0;
    border-bottom-left-radius: calc(var(--gap-medium) + 10px);
    border-bottom-right-radius: calc(var(--gap-medium) + 10px);
  }

  .page-oportunitati-transmed
    .mid-page
    .train
    .vagon-container
    .vagon
    .information-container
    .text-container {
    padding: var(--gap-small);
  }

  .page-oportunitati-transmed .mid-page .train .vagon-swiper .connection-line {
    top: calc(calc(100% - 8dvh) * -0.12);
  }
}

@media (max-width: 400px) {
  .page-oportunitati-transmed .mid-page .train .vagon-container .vagon {
    color: white;
  }
  .page-oportunitati-transmed .mid-page .train .vagon-container .vagon .card {
    display: none;
  }

  .page-oportunitati-transmed
    .mid-page
    .train
    .vagon-container
    .vagon
    .information-container {
    position: relative;
    margin: var(--gap-small);
    top: 0;
    left: 0;
  }

  .page-oportunitati-transmed .mid-page .train .vagon-swiper .connection-line {
    top: unset;
    bottom: 40px;
  }
}

@media (max-width: 300px) {
  .page-oportunitati-transmed .mid-page .train .vagon-swiper .connection-line {
    display: none;
  }
}

.page-oportunitati-transmed .mid-page .train .vagon .card .img {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}
.page-oportunitati-transmed .mid-page .train .vagon-container .wheels {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  z-index: -1;
}

.page-oportunitati-transmed
  .mid-page
  .train
  .vagon-container
  .wheels
  .left-wheel {
  display: flex;
  background-color: #474747;
  position: relative;
  height: 8dvw;
  width: 8dvw;
  left: 5%;
  top: -50%;
  border-radius: 50%;
}

.page-oportunitati-transmed
  .mid-page
  .train
  .vagon-container
  .wheels
  .right-wheel {
  display: flex;
  background-color: #474747;
  width: 8dvw;
  height: 8dvw;
  right: 5%;
  top: -50%;
  border-radius: 50%;
  position: relative;
}

.page-oportunitati-transmed .mid-page .train .rightLine {
  display: flex;
  position: relative;
  top: 10%;
}

.page-oportunitati-transmed .lower-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.page-oportunitati-transmed .lower-page .account {
  padding: var(--gap-medium) var(--gap-small);
  margin: var(--gap-xl) 0;
  background-color: var(--color-gray);

  display: flex;
  justify-content: center;

  border-radius: var(--border-radius-medium);
}

.page-oportunitati-transmed .mid-page .connection-line {
  border-top: 5px dashed var(--color-dark);
  position: relative;
  top: -4.2dvw;
}

@media (max-width: 500px) {
  .page-oportunitati-transmed .no-reviews {
    flex-direction: column;
  }
}

.page-oportunitati-transmed .reviews {
  align-self: center;
  margin: var(--gap-big) 0;

  display: flex;
  flex-direction: column;
  align-items: center;
}
