.footer * {
  color: white;
}

.footer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: strech;

  padding: 20px;

  background-color: var(--color-dark);
}

.footer .first-row {
  display: flex;
  flex-direction: row;
  align-items: strech;
  justify-content: flex-start;
}

.footer .logo {
  height: 70px;
  aspect-ratio: 1/1;
}

.footer .logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.footer .title-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  padding-left: 20px;
}

.footer .content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 700px;

  margin: 60px 0;
}

.footer .column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  gap: 3px;
  margin-bottom: 20px;
  list-style: none;
}

.footer .column .element {
  color: inherit;
  text-decoration: none;
}

.footer .column .element:hover {
  text-decoration: underline;
}

.footer .last-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 40px;
}

.footer .last-row .icon {
  margin: 0 15px;
}

.footer .last-row .right button {
  background-color: transparent;
  color: inherit;
  border: none;
  padding: 10px;
  border-radius: 10px;
}

.footer .last-row .right button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.footer .last-row .right .icon {
  font-size: 50px;
}

@media screen and (max-width: 550px) {
  .footer .content {
    flex-direction: column;
    align-items: center;
    align-self: center;
    text-align: center;
  }

  .footer .column {
    padding: var(--gap-medium) 0;
    align-items: center;
    text-align: center;
    gap: var(--gap-small);
  }

  .footer .logo {
    height: 40px;
  }
}

@media screen and (max-width: 310px) {
  .footer .last-row {
    flex-direction: column;
    height: 150px;
  }
}
