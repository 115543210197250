.page-oportunitati-transmed .reviews .list {
  gap: var(--gap-medium);
  margin-top: var(--gap-medium);
}

.page-oportunitati-transmed .reviews .gallery {
  margin: var(--gap-big) 0;
}
.page-oportunitati-transmed .reviews .gallery .mySwiper {
  width: 100%;
  height: 100%;
}

.page-oportunitati-transmed .reviews .gallery .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;

  height: calc(80dvh - var(--nav-height) - calc(2 * var(--gap-big)));

  border-radius: var(--border-radius-medium);
  overflow: hidden;
}

.page-oportunitati-transmed .reviews .gallery .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.page-oportunitati-transmed .reviews .country-title {
  color: var(--color-dark);
  text-align: center;
}
