#page-oportunitati-exchanges .reviews {
  position: relative;
}

#page-oportunitati-exchanges .reviews .overlay {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 2;
}
