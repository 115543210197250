@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  --color-fasmr-blue: #3090d8;
  --color-scope: #0181c1;
  --color-score: #2e4a9c;
  --color-dark: #474747;
  --color-light: #f4f4f4;
  --color-gray-dark: #d0d0d0;
  --color-gray: #e0e0e0;
  --color-gray-light: #ececec;

  --color-scome: #100016;
  --color-scoph: #f47d3b;
  --color-scora: #bd202a;
  --color-scorp: #5f903f;
  --color-scope: #0181c1;
  --color-score: #2e4a9c;

  --gap-xs: 4px;
  --gap-s: 6px;
  --gap-small: 12px;
  --gap-medium: 26px;
  --gap-big: 40px;
  --gap-xl: 60px;
  --gap-2xl: 120px;

  --border-radius-xs: 6px;
  --border-radius-small: 12px;
  --border-radius-medium: 24px;
  --border-radius-big: 40px;

  --box-shadow-small: 0 3px 8px 0px rgba(0, 0, 0, 0.4);
  --box-shadow-xs: 0 3px 8px 0px rgba(0, 0, 0, 0.2);
  --drop-shadow-small: 0 4px 4px rgba(0, 0, 0, 0.4);
  --drop-shadow-xs: 0 4px 4px rgba(0, 0, 0, 0.2);

  --nav-height: 70px;
  --content-height: calc(100dvh - var(--nav-height));
}

* {
  transition: all 0.2s ease;
  z-index: 1;
  -webkit-tap-highlight-color: transparent;
}

/** REGION: Fonts */
* {
  --font-family-primary: "Montserrat";
  --line-height: 1.05em;

  --display-font-size: 86px;
  --display-font-weight: 900;

  --headline-font-size: 64px;
  --headline-font-weight: 400;

  --title-font-size: 37px;
  --title-font-weight: 800;

  --subtitle-font-size: 31px;
  --subtitle-font-weight: 400;

  --body-font-size: 18px;
  --body-font-weight: 400;

  --label-font-size: 12px;
  --label-font-weight: 500;

  /* --label-font-size: var(--body-font-size);
  --label-font-weight: var(--body-font-weight); */

  --icons-font-size: 48px;
}

@media (max-width: 790px) {
  * {
    --display-font-size: 61px;
    --headline-font-size: 50px;
    --title-font-size: 29px;
    --subtitle-font-size: 24px;
    --body-font-size: 16px;
    --label-font-size: 12px;
    --icons-font-size: 30px;
  }
}

@media (max-width: 590px) {
  * {
    --display-font-size: 36px;
    --headline-font-size: 30px;
    --title-font-size: 20px;
    --subtitle-font-size: 17px;
    --body-font-size: 14px;
    --label-font-size: 12px;
    --icons-font-size: 30px;
  }
}

* {
  font-family: var(--font-family-primary);
  line-height: var(--line-height);
}

h1 {
  font-size: var(--display-font-size);
  font-weight: var(--display-font-weight);

  margin-bottom: 0;
  padding-bottom: 0;
}

h2 {
  font-size: var(--headline-font-size);
  font-weight: var(--headline-font-weight);

  margin-bottom: 0;
  padding-bottom: 0;
}

h3 {
  font-size: var(--title-font-size);
  font-weight: var(--title-font-weight);

  margin-bottom: 0;
  padding-bottom: 0;
}

h4 {
  font-size: var(--subtitle-font-size);
  font-weight: var(--subtitle-font-weight);

  margin-bottom: 0;
  padding-bottom: 0;

  margin-top: var(--gap-small);
  padding-top: 0;
  justify-self: flex-start;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}

body {
  font-size: var(--body-font-size);
  font-weight: var(--body-font-weight);
}

.label {
  font-size: var(--label-font-size);
  font-weight: var(--label-font-weight);
}

.icons,
.icon {
  font-size: var(--icons-font-size);
}

/** ENDREGION: Fonts */

html,
body {
  height: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
}

.stretcher {
  display: flex;
  flex-grow: 1;
}

img {
  object-fit: cover;
  display: block;
}

img.h {
  height: 100%;
  width: auto;
}

img.w {
  width: 100%;
  height: auto;
}

.fill-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin: 0;
  padding: 0;

  background-color: var(--color-fasmr-blue);
  color: white;

  border: 2px solid transparent;
  border-radius: 999em;
  text-decoration: none;
}

.fill-button:hover {
  background-color: transparent;
  border: 2px solid var(--color-fasmr-blue);
  color: var(--color-fasmr-blue);
}

.fill-secondary-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  margin: 0;
  padding: var(--gap-small) var(--gap-medium);

  background-color: white;
  color: var(--color-dark);

  border: 2px solid transparent;
  border-radius: 999em;

  text-decoration: none;
}

.fill-secondary-button:hover {
  background-color: transparent;
  border: 2px solid var(--color-dark);
}

button {
  cursor: pointer;
}

.transparent {
  background-color: transparent;
}

hr {
  --color: var(--color-dark);
  --width: 1.5px;
  border: none;
  height: var(--width);
  width: 90%;
  background: radial-gradient(circle, var(--color) 0, transparent 95%);
}

#root {
  overflow: visible;
}

.content-container {
  display: flex;
  flex-direction: column;
  width: min(1000px, calc(100dvw - 2 * var(--gap-small)));
}

.cards-grid {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap-small);
  justify-content: center;
  align-items: center;
}
