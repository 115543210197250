#page-contact {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: stretch;
  justify-content: flex-start;

  background-color: var(--color-light);

  padding: var(--gap-small);
}

#page-contact .background {
  position: absolute;
  margin-top: var(--gap-big);
  margin-left: var(--gap-big);
  height: 90dvh;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

#page-contact .background img {
  height: 100%;
  object-fit: cover;
  object-position: left;
}

#page-contact .header {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  gap: var(--gap-medium);

  padding: var(--gap-big);
}

#page-contact .header .element-grafic {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  flex: 1;
}

#page-contact .header .element-grafic img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#page-contact .text-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-small);
}

@media screen and (max-width: 1100px) {
  #page-contact .header {
    flex-direction: column;
    height: auto;
    padding: var(--gap-big) var(--gap-medium);
  }

  #page-contact .text-container {
    align-items: center;
    text-align: center;
  }
}

#page-contact .title {
  color: var(--color-fasmr-blue);
}

#page-contact .text {
  color: var(--color-dark);
}

#page-contact .text-container address {
  color: var(--color-dark);
  display: flex;
  flex-direction: row;
  gap: var(--gap-small);
  align-items: baseline;
  margin-top: var(--gap-medium);
}

#page-contact .text-container .icons {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  gap: var(--gap-medium);
}

#page-contact .text-container .icons > * {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: var(--color-fasmr-blue);
}

#page-contact .body {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#page-contact form {
  display: grid;
  grid-template-columns: 100px auto;
  gap: var(--gap-small);
  margin: var(--gap-medium);
  margin-top: var(--gap-big);
  justify-items: stretch;
  text-align: start;

  color: var(--color-dark);
}

#page-contact form input[type="text"],
#page-contact form textarea {
  border: 1px solid var(--color-gray-dark);
  border-radius: var(--border-radius-xs);
}

#page-contact form > *:not(.submit) {
  padding: var(--gap-s);
}

#page-contact #contact-form-message {
  resize: none;
  overflow: auto;
}

#page-contact .submit {
  justify-self: start;
}

@media screen and (max-width: 900px) {
  #page-contact form {
    grid-template-columns: 60px auto;
  }
}
