#page-despre-noi #istoric {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 50%,
    var(--color-light) 50%
  );
}

#page-despre-noi #istoric .card {
  background-color: white;
  color: var(--color-dark);

  column-count: 2;
  text-align: justify;
  column-gap: var(--gap-big);
  padding: var(--gap-big);
  border-radius: var(--border-radius-medium);
}

#page-despre-noi #istoric .container *:first-child {
  padding-bottom: var(--gap-big);
}

@media (max-width: 700px) {
  #page-despre-noi #istoric .card {
    column-count: 1;
  }
}
