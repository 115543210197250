#page-cd {

  display: flex;
  flex-direction: column;
 
  
}
#page-cd .header {
    display:flex;
    flex-direction: column;
    background-color: var(--color-fasmr-blue);
    width:100%;
    flex:1;
}
#page-cd .header .title {
    display:flex;
    align-items: center;
    align-self: center;
    margin-top:20px;
    color:white;
    
}

#page-cd .header .content-text {
    display:flex;
    align-self: center;
    align-items: stretch;
    margin-top:20px;
    margin-left:40px;
    margin-right:40px;
    color:white;
    text-align: justify;
    
    
}

#page-cd .header .image1c{

    margin-top: 20px;
    align-self: center;
    width: min(calc(100% - 2 * 40px), 1520px );

}

#page-cd .body .Membru-CD{
    display:flex;
    flex-direction: column;
    align-items: stretch; 
}

