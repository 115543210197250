button.button-primary-side {
  display: flex;
  align-items: center;

  padding: var(--gap-xs) var(--gap-medium);
  gap: var(--gap-small);

  border-radius: 999em;
  position: relative;

  font-size: var(--body-font-size);
}

button.button-primary-side.right {
  flex-direction: row;
  text-align: end;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: var(--gap-small);
}

button.button-primary-side.left {
  flex-direction: row-reverse;
  text-align: start;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: var(--gap-small);
}

button.button-primary-side img {
  height: 2em;
  z-index: 3;
}

button.button-primary-side:not(:hover) img.blue {
  display: none;
}

button.button-primary-side:hover img.white {
  display: none;
}

button.button-primary-side .line {
  position: absolute;
  top: calc(50% - 1px);
  display: flex;
  height: 2px;
  width: 30px;
}

button.button-primary-side .inside.line {
  background-color: white;
  z-index: 2;
}

button.button-primary-side:hover .inside.line {
  background-color: var(--color-fasmr-blue);
}

button.button-primary-side.right .inside.line {
  right: -2px;
}

button.button-primary-side.left .inside.line {
  left: -2px;
}

button.button-primary-side .outside.line {
  background-color: var(--color-fasmr-blue);
}

button.button-primary-side.right .outside.line {
  right: -15px;
}

button.button-primary-side.left .outside.line {
  left: -15px;
}

button.button-primary-side .outside.line::after {
  content: "";
  display: flex;
  position: absolute;
  top: calc(50% - 5px);
  height: 10px;
  width: 10px;
  background-color: var(--color-fasmr-blue);
  border-radius: 50%;
  overflow: hidden;
}

button.button-primary-side.right .outside.line::after {
  right: -5px;
}

button.button-primary-side.left .outside.line::after {
  left: -5px;
}
