.tree-navigation-menu {
  background-color: var(--color-light);
  /* min-height: calc(100dvh - var(--nav-height));
  max-height: calc(100dvh - var(--nav-height)); */
  height: calc(100dvh - var(--nav-height));
  position: relative;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.tree-navigation-menu .background-graphics {
  position: absolute;
  top: 0;
  right: 0;
  transform: rotateZ(180deg);
  width: 70%;
  z-index: 1;
  opacity: 0.5;
}

.tree-navigation-menu > .content {
  position: relative;
  z-index: 2;
  padding: 0 var(--gap-small);

  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.tree-navigation-menu-node {
  border-bottom: 1px solid var(--color-dark);
}

.tree-navigation-menu-node.w-padding {
  padding: var(--gap-medium) var(--gap-small);
}

.tree-navigation-menu-node.active {
  border-bottom: 2px solid var(--color-fasmr-blue);
}

.tree-navigation-menu-node .title {
  padding: var(--gap-medium) var(--gap-small);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tree-navigation-menu-node .title:hover {
  cursor: pointer;
}

.tree-navigation-menu-node .title .text {
  flex: 1;
}

.tree-navigation-menu-node .title img {
  height: 1.5em;
  margin-right: var(--gap-small);
}

.tree-navigation-menu-node:not(.active) .content {
  height: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.tree-navigation-menu-node .content {
  padding-bottom: var(--gap-medium);
  padding-left: var(--gap-small);
  border-left: 2px solid var(--color-fasmr-blue);
}

.navigation-menu-section.despre-noi {
  flex-grow: 0;
}
