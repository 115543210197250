.entire-page {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.entire-page .upper-page {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 0;
  position: relative;
}
.entire-page .upper-page .content-container {
  align-items: center;
  margin: var(--gap-medium) 0;
  gap: var(--gap-medium);
}

.entire-page .upper-page .background1 {
  display: flex;
  background-color: var(--color-fasmr-blue);
  width: 100%;
  height: 100%;
  z-index: 0;
  position: absolute;
  opacity: 48%;
}

.entire-page .upper-page .background2 {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  overflow: hidden;
}

.entire-page .upper-page .background2 .image {
  width: 100%;
  height: 100%;
}

.entire-page .upper-page .titlu {
  display: flex;
  color: white;
  text-align: center;
}
.entire-page .upper-page .subtitle {
  display: flex;
  color: white;
  text-align: justify;
}
.entire-page .upper-page .button {
  display: flex;
  color: white;
  cursor: pointer;
}

.entire-page .opportunity-section {
  height: max(
    calc(100dvh - var(--nav-height) - calc(2 * var(--gap-small))),
    600px
  );
  align-self: stretch;
  overflow: hidden;
}

.entire-page .mid-page {
  display: flex;
  flex-direction: column;
}

.entire-page .mid-page .color-container {
  display: flex;
  height: var(--gap-medium);
  background-color: var(--color-fasmr-blue);
}

.entire-page .mid-page .big-container {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  gap: var(--gap-small);
  flex: 1;
}

.entire-page .mid-page .big-container .pointer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: var(--gap-medium);
}

.entire-page .mid-page .left-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  gap: var(--gap-medium);
  flex: 1;
}

.entire-page .mid-page .main-container {
  display: flex;
  flex-direction: row;
  gap: var(--gap-medium);
  margin: var(--gap-big) 0;
  margin-right: var(--gap-medium);
}

.entire-page .mid-page .main-container .left-container .title {
  display: flex;
  color: var(--color-fasmr-blue);
  gap: var(--gap-small);
}
.entire-page .mid-page .main-container .left-container .text-container {
  display: flex;
  padding: 0 var(--gap-small);
  flex-direction: column;
  align-items: center;
}
.entire-page .mid-page .main-container .left-container .button {
  display: flex;
  padding: var(--gap-small) var(--gap-medium);
  background-color: var(--color-fasmr-blue);
  color: white;
  border-radius: var(--gap-big);
  cursor: pointer;
}

.entire-page .mid-page .main-container {
  display: flex;
  flex-direction: row;
}

.entire-page .mid-page .main-container .right-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.entire-page .mid-page .main-container .right-container .resource-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.entire-page
  .mid-page
  .main-container
  .right-container
  .resource-container
  img {
  object-fit: contain;
  object-position: top;
  width: 80%;
}

.entire-page .lower-page {
  display: flex;
  flex-direction: column;
  position: relative;
}
.entire-page .lower-page .color-container {
  display: flex;
  width: 100%;
  height: var(--gap-medium);
  flex-shrink: 0;
  background-color: var(--color-fasmr-blue);
}
.entire-page .lower-page .background {
  display: flex;
  justify-content: center;
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  max-height: 99%;
}

.entire-page .lower-page .background img {
  object-position: bottom;
  object-fit: contain;
}

.entire-page .lower-page .text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.entire-page .lower-page .text-container .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 13dvh;
  margin: var(--gap-medium) var(--gap-small) var(--gap-big);
  gap: var(--gap-small);
}

.entire-page .lower-page .text-container .title .resource-container {
  height: 100%;
}

.entire-page .lower-page .text-container .title img {
  object-fit: contain;
}

.entire-page .lower-page .text-container .title .litera {
  display: flex;
  margin: 0 var(--gap-small);
  color: var(--color-dark);
  font-size: 500%;
}

.entire-page .lower-page .text-container .subtitle {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.entire-page .lower-page .card-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: var(--gap-small) var(--gap-medium);
}

.entire-page .lower-page .button {
  display: flex;
  padding: var(--gap-small) var(--gap-medium);
  align-self: center;
  color: white;
  background-color: var(--color-fasmr-blue);
  border-radius: var(--gap-big);
  cursor: pointer;
}
.entire-page .lower-page .card-container .card-outgoing {
  display: flex;
}

.entire-page .lower-page .card-container .card-incoming {
  display: flex;
}
.entire-page .lower-page .card-container .text-container {
  display: none;
}

.entire-page .lower-page .card-container-2 {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
}

.entire-page .lower-page .card-container-2 > .button {
  align-self: center;
  margin-bottom: var(--gap-medium);
}

.entire-page .lower-page .card-container-2 .swiper {
  width: 100%;
}

.entire-page .lower-page .card-container-2 .swiper .swiper-slide {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1400px) {
  .entire-page .lower-page .card-container {
    display: none;
  }
}

@media (min-width: 1400px) {
  .entire-page .lower-page .card-container-2 {
    display: none;
  }
  .entire-page .lower-page .stretcher.bottom {
    display: none;
  }
}

@media (max-width: 650px) {
  .entire-page .mid-page .main-container {
    display: flex;
    flex-direction: column;
  }

  .entire-page .mid-page .main-container {
    margin-right: 0;
  }

  .entire-page .mid-page .big-container .pointer {
    display: none;
  }
}
