#pages-news-detail {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex start;
}

#pages-news-detail .header {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  align-self: stretch;
  background-color: var(--color-fasmr-blue);
  height: 60dvh;
  color: white;
}

#pages-news-detail .header .container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: var(--gap-medium);
  margin: var(--gap-medium) 0;
}

#pages-news-detail .header .text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  flex: 1;
  gap: var(--gap-medium);
}

#pages-news-detail .header .text .icons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-small);
}

#pages-news-detail .header a {
  color: white;
}

#pages-news-detail .header .foreground-image-container {
  flex: 1;
  aspect-ratio: 1/1;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  border-radius: var(--border-radius-big);
  border-top-right-radius: 0;
  overflow: hidden;
}

#pages-news-detail .header .foreground-image {
  display: flex;
  flex: 1;
}

#pages-news-detail .header .background-image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  opacity: 0.3;
}

#pages-news-detail .header .background-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

#pages-news-detail .bold {
  font-weight: bold;
}

@media (max-width: 900px) {
  #pages-news-detail .header {
    height: auto;
    max-height: 80dvh;
    text-align: center;
  }

  #pages-news-detail .header .container {
    flex-direction: column-reverse;
    align-items: center;
  }
  #pages-news-detail .header .text {
    align-items: center;
    flex: 0;
    gap: var(--gap-small);
  }
}

#pages-news-detail .body {
  display: flex;

  flex-direction: column;
  align-items: center;
  z-index: 0;

  gap: var(--gap-big);
  padding: var(--gap-big) 0;

  background-attachment: fixed;
}

#pages-news-detail .body .maincontainer {
  align-items: left;
  justify-content: stretch;

  background-color: var(--color-light);
  border-radius: var(--border-radius-medium);
  box-shadow: var(--box-shadow-xs);
}

#pages-news-detail .body .maincontainer .text {
  padding: calc(var(--gap-medium) + var(--border-radius-medium));
  color: var(--color-dark);
}

#pages-news-detail .body .nearfooter {
  background-color: var(--color-light);
  align-self: flex-start;
  padding: var(--gap-small) var(--gap-medium);
  color: var(--color-fasmr-blue);
  border-top-right-radius: 999px;
  border-bottom-right-radius: 999px;
  margin-right: var(--gap-medium);
  box-shadow: var(--box-shadow-xs);
}

#pages-news-detail .body .news-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
