#page-despre-noi #documents {
  background-color: var(--color-light);
}

#page-despre-noi #documents .container {
  display: flex;
  flex-direction: column;
  gap: var(--gap-medium);
  text-align: justify;

  color: var(--color-dark);
}

#page-despre-noi #documents .content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--gap-xl);
}

@media (max-width: 1000px) {
  #page-despre-noi #documents .content {
    grid-template-columns: 1fr;
    margin-right: var(--gap-medium);
  }
}
