.news-summary-card {
  display: flex;
  flex-direction: row;
  align-items: stretch;

  height: 400px;
  max-width: calc(100dvw - var(--gap-small));
  align-self: stretch;
  /* flex: 1; */

  background-color: var(--color-fasmr-blue);
  margin-bottom: 10px;
  border-radius: 0 var(--border-radius-medium) var(--border-radius-medium) 0;
  box-shadow: var(--box-shadow-small);

  overflow: hidden;

  text-decoration: none;
  color: inherit;
}

.news-summary-card:hover {
  transform: translate(var(--gap-small));
}

.news-summary-card .image {
  aspect-ratio: 1/1;
}

.news-summary-card .info-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  /* gap: var(--gap-medium); */
  padding: var(--gap-big) var(--gap-medium);
  margin-bottom: var(--gap-xs);
  margin-left: var(--gap-small);

  background-color: var(--color-gray-light);
  border-bottom-right-radius: var(--border-radius-medium);
}

.news-summary-card .title {
  background-color: var(--color-gray-light);

  margin-left: calc(-1 * (var(--gap-medium) + var(--gap-xl)));
  padding: var(--gap-small) var(--gap-medium);

  text-align: left;
}

.news-summary-card .content {
  text-align: justify;
  overflow: hidden;
}

.news-summary-card .last-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  margin: var(--gap-small) 0;
}

.news-summary-card:hover .chevron {
  color: var(--color-fasmr-blue);
}

@media screen and (max-width: 1000px) {
  .news-summary-card {
    height: 35dvw;
  }
}

@media screen and (max-width: 850px) {
  .news-summary-card .info-container {
    padding: 0 var(--gap-small);
  }

  .news-summary-card .title {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;

    margin-top: 0;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 650px) {
  .news-summary-card {
    height: 200px;
  }

  .news-summary-card .image {
    display: none;
  }
}
