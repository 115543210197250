#page-despre-noi {
  align-self: stretch;

  display: flex;
  flex-direction: column;
  align-items: center;
}

#page-despre-noi > * {
  padding-top: var(--gap-xl);
}

#page-despre-noi .container-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#page-despre-noi .container {
  align-items: stretch;
  margin: var(--gap-small);
}

#page-despre-noi .motto {
  color: var(--color-fasmr-blue);
  text-align: center;
}

#page-despre-noi .motto span {
  font-size: inherit;
  font-weight: bold;
}

#page-despre-noi .last-image .container {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  flex: 1;
  margin-bottom: 0;
}

#page-despre-noi .last-image .resource {
  width: 98dvw;
}

#page-despre-noi .w-gap {
  gap: var(--gap-small);
}
