#page-despre-noi #structura {
  background-color: var(--color-light);
  color: var(--color-dark);
}
#page-despre-noi #structura .container {
  gap: var(--gap-xl);
}

#page-despre-noi #structura .subsection {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--gap-medium);
}

#page-despre-noi #structura h2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

#page-despre-noi #structura .information {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-medium);
}

#page-despre-noi #structura .information .text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-medium);
}

#page-despre-noi #structura .information .text-container .title {
  color: var(--color-fasmr-blue);
}

#page-despre-noi #structura .information .text-container > .text {
  text-align: justify;
}

#page-despre-noi #structura .information button {
  padding: var(--gap-small) var(--gap-medium);
  border-radius: 999em;
  gap: var(--gap-small);
}

#page-despre-noi #structura .cards-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  align-items: space-between;
}

#page-despre-noi #structura .swiper {
  display: none;
  width: 240px;
  margin: 0;
}

#page-despre-noi #structura .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-medium);
  border-top-right-radius: 0;
}

@media (max-width: 560px) {
  #page-despre-noi #structura .cards-container {
    align-items: center;
  }
  #page-despre-noi #structura .cards-grid {
    display: none;
  }
  #page-despre-noi #structura .swiper {
    display: unset;
  }
}
