.search-box,
.search-box * {
  --height: calc(2 * var(--border-radius-medium));
  --border-radius: calc(var(--height) / 2);

  min-width: 0;
}

.search-box {
  width: 100%;
  height: var(--height);
  display: flex;
  align-items: center;
  color: var(--color-dark);
}

.search-box .input-container {
  display: flex;
  flex-direction: row;

  flex: 1;
  height: 100%;

  border-radius: var(--border-radius);
  background-color: var(--color-gray-light);
}

.search-box .input-container:focus-within,
.search-box .input-container.has-text {
  box-shadow: var(--box-shadow-small);
}

.search-box input {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;

  background-color: transparent;

  margin: 0;
  margin-left: var(--border-radius);
  padding: 0;
  border: none;
  color: inherit;

  font-size: var(--body-font-size);
  font-weight: var(--body-font-weight);
}

.search-box input:focus {
  outline: none;
}

.search-box button {
  margin: 0;
  padding: 0;
  border: none;
  height: 100%;
  min-width: var(--height);
  background-color: transparent;
  color: inherit;
}

.search-box .clear-button {
  transition: none;
}

.search-box .clear-button:hover {
  color: var(--color-fasmr-blue);
}

.search-box .search-button {
  background-color: var(--color-gray-light);
  border-radius: var(--border-radius);
  margin-left: var(--gap-medium);
  box-shadow: var(--box-shadow-small);
}

.search-box .search-button:hover {
  background-color: var(--color-fasmr-blue);
  color: white;
}
