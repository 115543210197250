.page-oportunitati-transmed .no-reviews {
  align-self: center;
  display: flex;
  flex-direction: row;
  margin-bottom: calc(-1 * var(--gap-big));
  justify-content: stretch;
  color: var(--color-dark);
  background-color: var(--color-light);
  padding: var(--gap-big);
  border-radius: var(--border-radius-medium) var(--border-radius-medium) 0 0;
  box-shadow: var(--box-shadow-xs);
  gap: var(--gap-small);
}

.page-oportunitati-transmed .no-reviews .big-container {
  display: flex;
  flex-direction: column;
  gap: var(--gap-medium);
  align-items: center;
  justify-content: center;
}
.page-oportunitati-transmed .no-reviews .big-container .text {
  display: flex;
  align-items: stretch;
}
.page-oportunitati-transmed .no-reviews .big-container .line-container {
  display: flex;
}
.page-oportunitati-transmed .no-reviews .image-container {
  display: flex;
}

.page-oportunitati-transmed .no-reviews .image-container img {
  object-fit: contain;
}

@media (max-width: 500px) {
  .page-oportunitati-transmed .no-reviews {
    flex-direction: column;
  }
}
