.card-information-big {
  background-color: white;
  color: var(--color-dark);
  padding: var(--gap-big);
  gap: var(--gap-big);
  border-radius: var(--border-radius-medium);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: justify;
}

.card-information-big.mirror {
  align-items: flex-end;
}

.card-information-big .text {
  flex: 1;
}

.card-information-big .last-row {
  align-self: stretch;
  align-items: flex-end;
  display: flex;
}

.card-information-big:not(.mirror) .last-row {
  flex-direction: row-reverse;
  margin-right: calc(-1 * var(--gap-big));
}

.card-information-big.mirror .last-row {
  flex-direction: row;
  margin-left: calc(-1 * var(--gap-big));
}

.card-information-big .last-row .short-content {
  flex: 1;
  display: flex;
  align-items: stretch;
  position: relative;
}

.card-information-big:not(.mirror) .last-row .short-content {
  flex-direction: row-reverse;
}

.card-information-big.mirror .last-row .short-content {
  flex-direction: row;
}

.card-information-big .last-row .short-content .button {
  display: flex;
  position: relative;
}

.card-information-big .last-row .short-content .button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  height: 1px;
  background-color: var(--color-fasmr-blue);
}

.card-information-big .last-row .lines {
  display: flex;
  flex: 1;
  align-items: stretch;
}

.card-information-big .last-row .lines::after {
  content: "";
  width: var(--gap-small);
}

.card-information-big:not(.mirror) .last-row .lines {
  flex-direction: row-reverse;
}

.card-information-big.mirror .last-row .lines {
  flex-direction: row;
}

.card-information-big .last-row .lines .top {
  border-top: 1px solid var(--color-fasmr-blue);
  flex: 1;
  min-width: 2px;
}

.card-information-big .last-row .lines .bottom {
  border-bottom: 1px solid var(--color-fasmr-blue);
  flex: 1;
  position: relative;
  min-width: 2px;
}

.card-information-big .last-row .lines .bottom::after {
  content: "";
  position: absolute;
  bottom: -3px;
  display: flex;
  height: 5px;
  width: 5px;
  background-color: var(--color-fasmr-blue);
  border-radius: 50%;
}

.card-information-big:not(.mirror) .last-row .lines .bottom::after {
  left: 0;
}

.card-information-big.mirror .last-row .lines .bottom::after {
  right: 0;
}

.card-information-big:not(.mirror) .last-row .lines svg {
  transform: scaleX(-1);
}

.card-information-big .last-row .icons {
  height: 75px;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  overflow: hidden;
}
