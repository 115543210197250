.card-structure {
  position: relative;
  height: 370px;
  width: 240px;
  background-color: var(--color-gray-light);

  border-radius: var(--border-radius-medium);
  border-top-right-radius: 0;

  perspective: 700px;
  overflow: hidden;

  text-decoration: none;
  color: inherit;
}
/* 
.card-person:hover {
  transform: rotate(3deg);
} */

.card-structure .logo-container {
  height: 240px;
  width: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  overflow: hidden;
}

.card-structure .logo {
  flex: 1;
  padding: var(--gap-big);
  overflow: hidden;
}

.card-structure .logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.card-structure .text-container {
  height: calc(370px - 240px);

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;
}

.card-structure .text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;

  width: calc(100% - 2 * var(--gap-big));
}

@media (min-width: 400px) {
  .card-structure {
    height: 480px;
    width: 320px;
  }
  .card-structure .logo-container {
    height: 346px;
  }
  .card-structure .text-container {
    height: calc(480px - 346px);
  }
}
