#page-index .intro {
  height: var(--content-height);
  width: 100%;
  position: relative;
  background-color: var(--color-fasmr-blue);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  color: white;

  text-align: center;
}

#page-index .intro .cover-logo {
  max-width: 1000px;
  margin: var(--gap-medium);
  flex: 1;
}
#page-index .intro .cover-logo img {
  object-fit: contain;
}

#page-index section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: var(--color-dark);
  padding: var(--gap-big) 0;
}

#page-index .about {
  background-color: var(--color-light);
  color: var(--color-dark);
}

#page-index .container {
  align-items: stretch;
  text-align: center;
  gap: var(--gap-medium);
  margin:40px;
}

#page-index .text-container {
  text-align: justify;
}

#page-index .about .cover {
  border-radius: var(--border-radius-small);
}

#page-index .fields,
#page-index .projects {
  background-color: var(--color-gray-light);
  color: var(--color-dark);
}

#page-index .fields .content {
  display: grid;
  grid-template-columns: 300px auto;
  justify-items: stretch;
  align-items: start;
  gap: var(--gap-medium);
  
}

#page-index .fields .content .card {
  aspect-ratio: 1/1;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-medium);
  border-top-right-radius: 0;
  padding: var(--gap-small);
  height:min(170px,50dvh);
}

@media (max-width: 700px) {
  #page-index .fields .content {
    grid-template-columns: 1fr;
  }
  
}
@media (max-width:950px){
  #page-index .fields .content .card {
    height: 30dvh;
  }
}
@media (max-width:865px){
  #page-index .fields .content .card {
    height: 33dvh;
  }
}

@media (max-width: 700px) and (min-width: 400px) {
  #page-index .fields .content {
    justify-items: center;
  }
  #page-index .fields .content .card {
    width: 300px;
  }
}

#page-index .fields .content .card.scome {
  background-color: var(--color-scome);
  
}

#page-index .fields .content .card.scoph {
  background-color: var(--color-scoph);
}

#page-index .fields .content .card.scorp {
  background-color: var(--color-scorp);
}

#page-index .fields .content .card.scora {
  background-color: var(--color-scora);
}

#page-index .fields .content .card.scope {
  background-color: var(--color-scope);
}

#page-index .fields .content .card.score {
  background-color: var(--color-score);
}

#page-index .fields .content .description {
  text-align: justify;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-medium);
  align-content: stretch;
}

#page-index .fields .content .description .fill-secondary-button {
  border-top-right-radius: 0;
}



#page-index .join {
  padding: 0;
  background-color: var(--color-light);

  border-bottom: 5px solid var(--color-fasmr-blue);
}

#page-index .join .paper-cut {
  background-color: var(--color-gray-light);
  width: 100%;
}

#page-index .join .content {
  display: flex;
  flex-direction: row;
  align-items: stretch;

  margin: var(--gap-medium);
  margin-bottom: 0;
  gap: var(--gap-medium);
}

#page-index .join .text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: justify;
  justify-content: center;
  gap: var(--gap-small);
  margin-bottom: var(--gap-medium);
}

#page-index .join .image {
  width: 50%;
}

@media (max-width: 1000px) {
  #page-index .join .content {
    flex-direction: column;
    align-items: center;
  }

  #page-index .join .image {
    width: 100%;
  }
}

#page-index .news {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-big);

  background-image: var(--background-image-url);
  background-attachment: fixed;
}

#page-index .support {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--color-fasmr-blue);
  color: white;
  padding: var(--gap-big) var(--gap-medium);

  flex-wrap: wrap;
  gap: var(--gap-medium);
}

#page-index .support .fill-secondary-button:hover {
  color: white;
  border-color: white;
}

#page-index .support .text {
  display: flex;
  flex-direction: row;
  gap: var(--gap-small);
  font-size: var(--headline-font-size);
}

#page-index > *:not(.about) .fill-button {
  border-radius: 999em;
  padding: var(--gap-small) var(--gap-medium);
}
