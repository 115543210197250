#page-despre-noi #description {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;

  --text-container-padding: var(--gap-big);
  --image-container-displacement: var(--gap-xl);
}

#page-despre-noi #description .text-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: var(--gap-big);
  padding: var(--text-container-padding);
  padding-right: calc(
    var(--text-container-padding) + var(--image-container-displacement)
  );

  background-color: var(--color-light);
  color: var(--color-dark);

  border-radius: var(--border-radius-medium);
}

#page-despre-noi #description .image-container {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  height: 400px;
  /* aspect-ratio: 1/1; */

  margin-left: calc(-1 * var(--image-container-displacement));
}

#page-despre-noi #description .image-container .image {
  flex: 1;
  margin: var(--gap-medium) 0;
  overflow: hidden;
}

#page-despre-noi #description .image-container .image img {
  object-fit: contain;
  object-position: top;
}

@media (max-width: 800px) {
  #page-despre-noi #description .image-container {
    height: 300px;
  }
}

@media (max-width: 590px) {
  #page-despre-noi #description .image-container {
    height: 200px;
  }
}

@media (max-width: 470px) {
  #page-despre-noi #description {
    flex-direction: column-reverse;
  }
  #page-despre-noi #description .image-container {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  }
  #page-despre-noi #description .text-container {
    padding-right: var(--text-container-padding);
  }

  #page-despre-noi #description .image-container .image {
    margin-top: 0;
  }
}
