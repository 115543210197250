.exchanges-button {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  color: var(--color-dark);

  filter: drop-shadow(var(--drop-shadow-xs));
  text-decoration: none;

  --color1: var(--color-gray-light);
  --color2: var(--color-gray);

  z-index: 3;
}

.exchanges-button.active {
  /* --color1: var(--color-fasmr-blue); */
  --color2: var(--color-fasmr-blue);
}

.exchanges-button:hover {
  cursor: pointer;
  filter: drop-shadow(var(--drop-shadow-small));

  --color1: var(--color-fasmr-blue);
  /* --color2: var(--color-fasmr-blue); */
}

.exchanges-button .element {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  overflow: hidden;

  border-radius: var(--border-radius-small);
  background-color: white;
}

.exchanges-button .element::before,
.exchanges-button .element::after {
  content: "";
  height: var(--gap-medium);
  background-color: var(--color2);
}

.exchanges-button .element .inside {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-evenly;
  overflow: hidden;
  gap: var(--gap-small);
  padding: var(--gap-small);
}

.exchanges-button .elements {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  gap: var(--gap-small);
}

.exchanges-button .elements * {
  height: var(--gap-small);
  flex: 1;
  background-color: var(--color1);
  border-radius: var(--border-radius-xs);
}

@media (max-width: 500px) {
  .exchanges-button .picture {
    width: 40px;
  }
}
