.card-person {
  height: 410px;
  width: 240px;
  background-color: var(--color-gray-light);

  border-radius: var(--border-radius-medium);
  border-top-right-radius: 0;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  overflow: hidden;

  perspective: 700px;
}
/* 
.card-person:hover {
  transform: rotate(3deg);
} */

.card-person .profile {
  height: 280px;
  overflow: hidden;
}

.card-person .profile img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: bottom;
  overflow: hidden;
}

.card-person .text {
  flex: 1;
  background-color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;
  padding: var(--gap-small);
}

.card-person .text .title {
  color: var(--color-fasmr-blue);
}
