.exchanges-out-review {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;

  padding: var(--gap-medium);
  border: 1px solid var(--color-gray-dark);
  border-radius: var(--border-radius-medium);
  color: var(--color-dark);

  gap: var(--gap-small);
}

.exchanges-out-review .left {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-big);
  text-align: center;
}

.exchanges-out-review .left .profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-small);
}

.exchanges-out-review .left .city {
  background-color: var(--color-fasmr-blue);
  color: white;

  align-self: flex-start;
  margin-left: calc(-1 * var(--gap-medium));
  padding: var(--gap-small) var(--gap-medium);

  border-top-right-radius: 999em;
  border-bottom-right-radius: 999em;
}

@media (max-width: 550px) {
  .exchanges-out-review {
    flex-direction: column;
  }

  .exchanges-out-review .left {
    width: unset;
    gap: var(--gap-medium);
    flex-direction: column-reverse;
    margin-top: calc(-1 * var(--gap-medium));
  }

  .exchanges-out-review .left .city {
    align-self: unset;
    border-radius: var(--border-radius-small);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin: 0;
  }
}

.exchanges-out-review .right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-medium);
}

.exchanges-out-review .right .part-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--gap-small);
}

.exchanges-out-review .right .part-container .text-container {
  flex: 1;
  align-self: center;
}

@media (max-width: 400px) {
  .exchanges-out-review {
    padding: var(--gap-small);
  }
  .exchanges-out-review .left .city {
    padding: var(--gap-small);
  }
  .exchanges-out-review .left {
    margin-top: calc(-1 * var(--gap-small));
  }
}
