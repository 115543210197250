.pin-map {
  position: relative;
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;

  max-height: max(
    calc(100dvh - var(--nav-height) - calc(2 * var(--gap-medium))),
    500px
  );

  margin-bottom: var(--gap-xl);
}

.pin-map .map {
  /* object-fit: contain;
  width: 100%;

  flex: 1 0
    max(calc(100dvh - var(--nav-height) - calc(2 * var(--gap-medium))), 500px); */

  /* height: 1000px; */
  /* flex-basis: max(
    calc(100dvh - var(--nav-height) - calc(2 * var(--gap-medium))),
    500px
  ); */

  object-fit: contain;
  height: 100%;
  width: 100%;
  /* width: 100%; */
}

.pin-map button {
  font-size: unset;
  background: transparent;
  padding: 0;
  margin: 0;
  border: none;
}

.pin-map .pin-group {
  position: absolute;
  cursor: pointer;
  z-index: 1;
  transition: z-index 0s;
}

.pin-map .pin-group:hover {
  z-index: 2;
}

.pin-map .pin-group .pin {
  position: relative;
}

.pin-map .pin-group .text-container,
.pin-map .pin-group .pins-container .pin .text-container {
  position: absolute;
  bottom: var(--multiple-gap-padding);
  left: 50%;
  transform: translate(-50%, 100%);

  display: none;

  flex-direction: column;
  align-items: center;
  text-align: center;

  background-color: var(--color-light);
  padding: var(--gap-small);
  border-radius: var(--border-radius-small);
}

.pin-map .pin-group.expanded .text-container,
.pin-map .pin-group:hover .pin.single .text-container,
.pin-map .pin-group .pins-container .pin:hover .text-container,
.pin-map
  .pin-group
  .pins-container.around
  .pin-container:hover
  .text-container {
  display: flex;
}

.pin-map .pin-group .pins-container.stacked {
  position: relative;

  display: flex;
  flex-direction: row;
  gap: var(--gap-xs);
}

.pin-map .pin-group .pins-container .pin {
  position: absolute;
  top: 0;
  filter: drop-shadow(var(--drop-shadow-xs));
}

.pin-map .pin-group.expanded .pins-container.stacked .pin {
  position: static;
}

.pin-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  padding: 10px;
}

.pin-list-item {
  background: #f0f0f0;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.pin-list-item h3 {
  margin: 0;
  font-size: 1.2em;
}

.pin-list-item ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.pin-list-item li {
  cursor: pointer;
  padding: 5px;
}

#mobile-version {
  display: flex;
  flex-direction: column;
  margin-top: var(--gap-medium);
  align-self: stretch;
  gap: var(--gap-small);
  color: var(--color-dark);
}

.mobile-pin-group {
  padding: var(--gap-small);
  border-radius: var(--gap-small);
  border: 2px solid #ddd;
}
.mobile-pin-group .mobile-pin-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.mobile-pin-group .mobile-pin-header .title {
  display: flex;
  padding: var(--gap-xs);
}

.mobile-pin-group .mobile-pin-header .icon {
  display: flex;
  align-items: center;
}
.mobile-pin {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  padding: var(--gap-small);
  border-top: 1px solid #eee;
}

.mobile-pin-info h4 {
  display: flex;
  flex-direction: row;
  text-align: left;
  justify-content: flex-start;
}
