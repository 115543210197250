#page-news {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  background-image: var(--background-image-url);
  background-repeat: repeat;
  background-attachment: fixed;
}

#page-news .header {
  align-items: center;
}

#page-news .cover {
  position: absolute;
  width: 100%;
  height: min(max(min(75dvh, 70dvw), 500px), 100dvh);
  filter: drop-shadow(var(--drop-shadow-xs));
}

#page-news .cover img {
  object-position: bottom;
}

#page-news .header .page-title {
  color: white;

  margin-block-start: 0;
  margin-block-end: 0;

  margin-top: max(5dvw, var(--gap-small));
}

#page-news .header .page-subtitle {
  color: white;
  text-align: justify;

  margin-top: 30px;
}

#page-news .search {
  margin-top: max(3dvw, var(--gap-small));
}

#page-news .pagination {
  margin: max(3dvw, var(--gap-small));
}

#page-news .news {
  position: relative;
  align-items: center;
}

#page-nonews {
  display: flex;
  flex-direction: row;
  margin-top: var(--gap-2xl);
  justify-content: stretch;
  color: var(--color-dark);
  background-color: var(--color-light);
  padding: var(--gap-big);
  border-radius: var(--border-radius-medium) var(--border-radius-medium) 0 0;
  box-shadow: var(--box-shadow-xs);
  gap: var(--gap-small);
}

#page-nonews .big-container {
  display: flex;
  flex-direction: column;
  gap: var(--gap-medium);
  align-items: center;
  justify-content: center;
}
#page-nonews .big-container .text {
  display: flex;
  align-items: stretch;
}
#page-nonews .big-container .line-container {
  display: flex;
}
#page-nonews .image-container {
  display: flex;
}

#page-nonews .image-container img {
  object-fit: contain;
}

@media (max-width: 500px) {
  #page-nonews {
    flex-direction: column;
  }
}

@media (max-width: 220px) {
  #page-news .news {
    margin-top: var(--gap-big);
  }
}
