#in-construction {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: var(--gap-xl);
  color: var(--color-dark);
  margin: var(--gap-big) var(--gap-small);
}

@media (max-width: 450px) {
  #in-construction {
    flex-direction: column-reverse;
  }
}

#in-construction .text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-medium);
}

#in-construction .text-container .button {
  padding: var(--gap-small) var(--gap-medium);
  gap: var(--gap-small);
}
@media (min-width: 450px) {
  #in-construction .image-container {
    height: 70dvh;
  }
}

#in-construction .image-container img {
  object-fit: contain;
}
