.componenta {
  display: flex;
  flex-direction: row;
  width: 100%;
  background: linear-gradient(to top, #cdc9c9, white);
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-color: var(--color-fasmr-blue);
  align-items: stretch;
}

.componenta.reverse {
  flex-direction: row-reverse;
}

.componenta .text-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 40px;
}

.componenta.reverse .text-content {
  margin-right: 40px;
  margin-left: unset;
}

.componenta .text-content .functie {
  display: flex;
  align-items: stretch;
  margin-left: 20px;
  margin-top: 50px;
  color: var(--color-fasmr-blue);
}

.componenta.reverse .text-content .functie {
  margin-left: unset;
}

.componenta .text-content .descriere {
  display: flex;
  align-items: stretch;
  margin-left: 20px;
  margin-top: 30px;
  text-align: justify;
}

.componenta.reverse .text-content .descriere {
  margin-right: 20px;
  text-align: justify;
  margin-left: unset;
}

.componenta .text-content .mail-details {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: 20px;
  margin-top: 30px;
}

.componenta.reverse .text-content .mail-details {
  margin-left: unset;
}

.componenta .text-content .mail-details .icon {
  display: flex;
  margin-right: 10px;
}

.componenta .text-content .mail-details .email {
  display: flex;
}

.componenta .text-content .nume {
  display: flex;
  margin-left: 20px;
  margin-top: 5px;
  margin-bottom: 15px;
}
.componenta.reverse .text-content .nume {
  margin-left: unset;
}

.componenta .imagine-profil {
  display: flex;
  flex: 1;
  overflow: hidden;
  margin-right: 40px;
  max-height: 500px;
}

.componenta .imagine-profil .imagine {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: bottom;
}

@media (max-width: 1350px) {
  .componenta.reverse {
    display: flex;
    flex-direction: column;
  }
  .componenta {
    display: flex;
    flex-direction: column;
  }
  .componenta .text-content {
    display: flex;
    justify-content: stretch;
    margin-left: 10px;
    margin-right: 70px;
  }
  .componenta .text-content .functie {
    margin-left: unset;
  }
  .componenta .text-content .descriere {
    margin-left: unset;
  }
  .componenta .text-content .mail-details {
    margin-left: unset;
  }
  .componenta .text-content .nume {
    margin-left: unset;
  }

  .componenta.reverse .text-content {
    display: flex;
    justify-content: stretch;
    margin-left: 70px;
    margin-right: 10px;
    align-items: flex-end;
  }

  .componenta.reverse .text-content .functie {
    text-align: right;
  }
  .componenta.reverse .text-content .descriere {
    margin-right: unset;
  }
  .componenta .text-content .nume {
    text-align: right;
  }

  .componenta .imagine-profil {
    display: flex;
    overflow: hidden;
    max-height: unset;
    width: 50%;
    align-self: center;
  }
  .componenta .imagine-profil .imagine {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
