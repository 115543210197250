.navigation-menu-section.noutati {
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.navigation-menu-section.noutati .title {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-bottom-style: solid;
  border-width: 2px;
  border-color: var(--color-gray);
  padding-bottom: 1%;
  font-weight: bold;
  color: var(--color-dark);
  text-decoration: none;
}

.navigation-menu-section.noutati .cols {
  display: flex;
  flex-direction: row;
  align-items: none;
  justify-content: flex-start;
  flex: 1;
}
.navigation-menu-section.noutati .col {
  display: flex;
  flex-direction: column;
  background-color: none;
  flex: 1;
  border-right-style: solid;
  border-width: 1px;
  border-color: var(--color-gray);
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--color-dark);
  text-decoration: none;
}

.navigation-menu-section.noutati .col:hover,
.navigation-menu-section.noutati .title:hover {
  color: var(--color-fasmr-blue);
}

.navigation-menu-section.noutati .p1 {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  border-bottom-style: solid;
  border-width: 1px;
  border-color: var(--color-gray);
  padding: var(--gap-small);
  text-align: center;
}

.navigation-menu-section.noutati .p2 {
  --lines: 8;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--lines);
  overflow: hidden;
  height: calc(1em * var(--lines) - 2px);
  padding: var(--gap-small);
}

.bold {
  font-weight: bold;
}

.navigation-menu-section.noutati .col.last {
  border-right: none;
}
