#page-despre-noi #asociatii {
  background-color: var(--color-gray-light);
  color: var(--color-dark);
}

#page-despre-noi #asociatii .information {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-medium);
}

#page-despre-noi #asociatii .information .text {
  text-align: justify;
  align-items: center;
}

#page-despre-noi #asociatii button {
  align-self: flex-end;
  padding: var(--gap-small) var(--gap-medium);
  border-radius: 999em;
  gap: var(--gap-small);
}

#page-despre-noi #asociatii .cards-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  align-items: space-between;
  gap: var(--gap-medium);
}

#page-despre-noi #asociatii .cards-container h3 {
  align-self: center;
  text-align: center;
  margin: var(--gap-big) 0 var(--gap-small) 0;
}

#page-despre-noi #asociatii .cards-container .card * {
  font-weight: bold;
}


#page-despre-noi #asociatii .swiper {
  display: none;
  width: 240px;
  margin: 0;
}

#page-despre-noi #asociatii .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-medium);
  border-top-right-radius: 0;
}

@media (max-width: 560px) {
  #page-despre-noi #asociatii .cards-container {
    align-items: center;
  }
  #page-despre-noi #asociatii .cards-grid {
    display: none;
  }
  #page-despre-noi #asociatii .swiper {
    display: unset;
  }
}
