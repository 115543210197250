.resource-container {
  position: relative;
}

.resource-container.image {
  overflow: hidden;
}

.resource-container img {
  /* position: absolute; */
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
