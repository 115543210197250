#form230 {
  background-color: var(color-light);
  display: flex;
  flex-direction: column;
  align-items: center;
}

#form230 > header {
  width: 100%;
}

#form230 .content {
  text-align: center;
  margin: var(--gap-big) 0;
  gap: var(--gap-medium);
}

#form230 .card {
  padding: var(--gap-medium);
  background-color: var(--color-gray-light);
  border-radius: var(--border-radius-small);

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gap-medium);
}

#form230 .card iframe {
  height: 80dvh;
  border: 2px solid var(--color-gray);
  align-self: stretch;
}

#form230 .send form {
  display: grid;
  grid-template-columns: auto auto;
  gap: var(--gap-small);
  margin: var(--gap-medium);
  margin-top: var(--gap-big);
  justify-items: stretch;
  text-align: start;

  color: var(--color-dark);
}

#form230 .send form input[type="text"] {
  border: 1px solid var(--color-gray-dark);
  border-radius: var(--border-radius-xs);
}

#form230 .send form > *:not(.submit, [type="file"]) {
  padding: var(--gap-s);
}

#form230 .send form input[type="checkbox"] {
  margin: 0;
  margin-right: var(--gap-xs);
  height: 1em;
  width: 1em;
}

#form230 .send .submit {
  justify-self: start;
  border-radius: 999em;
}

@media screen and (max-width: 500px) {
  /* #form230 .send form {
    width: calc(800px - var(--gap-medium));
  } */
}
