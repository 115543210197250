.punga {
  display: flex;

  --border-weight: 10px;
}
.punga .all-container {
  max-width: 370px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.punga .all-container .upper-container {
  display: flex;
  flex-direction: row;
  justify-content: center;

  padding: 0 var(--gap-big);

  border: var(--border-weight) solid var(--color-gray-light);
  border-bottom: none;
  /* align-self: center; */
  margin-bottom: calc(-1 * var(--border-weight));
  background-color: var(--color-light);

  border-radius: var(--border-radius-small) var(--border-radius-small) 0 0;
}

.punga .all-container .upper-container .upper-container-number {
  display: flex;
  margin-top: var(--gap-small);
  color: red;
  justify-content: center;
  align-self: center;
}

.punga .all-container .bottom-container {
  display: flex;
  flex-direction: row;
  gap: min(15%, 10dvw);
  align-self: stretch;
  justify-content: center;
  z-index: 0;
}

.punga .all-container .bottom-container .bottom-element-container {
  display: flex;
  width: 20px;
  height: 55px;
  border: var(--border-weight) solid var(--color-gray-light);
  border-top: none;
  margin-top: calc(-1 * var(--border-weight));
  /* border-top-color: #880808;
  border-top-width: 10px; */
  border-radius: 0 0 var(--border-radius-small) var(--border-radius-small);
}

.punga .all-container .bottom-container .bottom-image img {
  transform: rotateZ(180deg);
  height: 130%;
}

.punga .main-container {
  display: flex;
  flex-direction: column;
  aspect-ratio: 2/3;
  border-width: var(--border-weight);
  border-color: var(--color-gray-light);
  border-style: solid;
  z-index: -1;
  overflow: hidden;
  background-color: var(--color-light);

  border-radius: var(--border-radius-medium);
}

@media (max-width: 300px) {
  .punga .main-container {
    aspect-ratio: 1/2;
  }
}

.punga .main-container .main-container-text {
  display: flex;
  text-align: justify;
  padding: var(--gap-small);
}
