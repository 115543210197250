.button-clipboard-copy {
  border: none;
  padding: 0;
  margin: 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  background-color: transparent;
}

.button-clipboard-copy span.label {
  position: absolute;
  top: 0;
  font-size: var(--label-font-size);
  font-weight: var(--label-font-weight);
  text-wrap: nowrap;
  padding: var(--gap-small);
  border-radius: var(--border-radius-small);
  box-shadow: var(--box-shadow-xs);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  z-index: 5;
  background-color: var(--color-light);
}

.button-clipboard-copy span.label::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background-color: var(--color-light);
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.button-clipboard-copy:hover span.label {
  top: -100%;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}
