#page-oportunitati-exchanges #outgoings {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#page-oportunitati-exchanges #outgoings .continent {
  position: absolute;
  transform: translate(-50%, -50%);

  --l: 26px;

  border-radius: 999em;
  color: white;
  aspect-ratio: 1/1;
  height: var(--l);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--gap-small);
  align-self: stretch;
}

#page-oportunitati-exchanges #outgoings .continent-name {
  display: flex;
  align-items: center;

  margin: var(--gap-medium) var(--gap-small);
  color: var(--color-dark);
}

#page-oportunitati-exchanges #outgoings .continent.scope:hover,
#page-oportunitati-exchanges #outgoings .continent.score:hover {
  cursor: pointer;
  background-color: white;
  color: var(--color-dark);
}

#page-oportunitati-exchanges #outgoings .continent.scope {
  background-color: var(--color-scope);
  border: 2px solid var(--color-scope);
}

#page-oportunitati-exchanges #outgoings .continent.score {
  background-color: var(--color-score);
  border: 2px solid var(--color-score);
}

#page-oportunitati-exchanges #outgoings .country {
  position: absolute;
  transform: translate(-50%, -100%);

  display: flex;
  flex-direction: column;
  align-items: center;

  color: white;
  filter: drop-shadow(var(--drop-shadow-small));
}

#page-oportunitati-exchanges #outgoings .country .title {
  color: transparent;
}

#page-oportunitati-exchanges #outgoings .country:hover {
  cursor: pointer;
}

#page-oportunitati-exchanges #outgoings .country:hover .title {
  color: unset;
}

#page-oportunitati-exchanges .list {
  padding-top: var(--gap-big);
  gap: var(--gap-medium);
}

#page-oportunitati-exchanges #outgoings .continent-map {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;

  margin-bottom: var(--gap-medium);
}

#page-oportunitati-exchanges #outgoings .close {
  position: absolute;
  top: 0;
  left: 0;
  margin: var(--gap-medium);
  color: var(--color-dark);
  background-color: white;

  border: none;

  height: 50px;
  width: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 999em;

  z-index: 2;
}

#page-oportunitati-exchanges #outgoings .close:hover {
  background-color: var(--color-dark);
  color: white;
}
