#sustine {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  width: 100%;
  background-color: var(--color-light);
  min-height: max(75dvh, 700px);
}

#sustine img {
  object-fit: contain;
}

#sustine .header-bar {
  background-color: var(--color-fasmr-blue);
  color: var(--color-light);

  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-end;
  padding: var(--gap-medium) var(--gap-big);
  gap: var(--gap-medium);
}

#sustine .header-bar .icon {
  font-size: var(--display-font-size);
}

#sustine .content {
  align-self: center;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
}

#sustine .content > * {
  width: 50%;
}

#sustine .content > .information {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  padding: var(--gap-medium) 0;
  text-align: center;
  color: var(--color-fasmr-blue);
  gap: var(--gap-medium);
}

#sustine .buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--gap-small);
}

#sustine .buttons > * {
  padding: var(--gap-small);
  text-decoration: none;
}

#sustine .to230container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: var(--gap-medium);
}

#sustine .to230container > .information {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-small);
}

#sustine .to230container .fill-button {
  padding: var(--gap-small) var(--gap-big);
  text-decoration: none;
}

@media (max-width: 550px) {
  #sustine {
    min-height: unset;
  }

  #sustine .content {
    flex-direction: column-reverse;
  }

  #sustine .content > * {
    width: unset;
  }
}
